<!--
// nuxt-ui/components/context-shared/ContextLogoLeagueFooter.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import contextLeagueLogo from "~/src/region/datasources/contextFooterLeagueLogo.json";

const { isSpain, isAmericas, isWorldCup, isItaly } = useRegions();
const { isKings, isQueens } = useWebContext();
</script>

<template>
  <NuxtImg
    v-if="isKings && isSpain"
    :src="contextLeagueLogo.kingsSpain.src"
    :alt="contextLeagueLogo.kingsSpain.alt"
    class="logo"
    loading="lazy"
    width="312"
    height="68"
    sizes="189px md:312px"
  />
  <NuxtImg
    v-if="isKings && isAmericas"
    :src="contextLeagueLogo.kingsAmericas.src"
    :alt="contextLeagueLogo.kingsAmericas.alt"
    class="logo"
    loading="lazy"
    width="312"
    height="80"
    sizes="189px md:312px"
  />
  <NuxtImg
    v-if="isKings && isItaly"
    :src="contextLeagueLogo.kingsItaly.src"
    :alt="contextLeagueLogo.kingsItaly.alt"
    class="logo"
    loading="lazy"
    width="312"
    height="66"
    sizes="189px md:312px"
  />

  <NuxtImg
    v-if="isKings && isWorldCup"
    :src="contextLeagueLogo.kingsWorldCup.src"
    :alt="contextLeagueLogo.kingsWorldCup.alt"
    class="logo-kwc"
    loading="lazy"
    width="220"
    height="86"
    sizes="160px md:220px"
  />

  <NuxtImg
    v-if="isQueens && isSpain"
    :src="contextLeagueLogo.queensSpain.src"
    :alt="contextLeagueLogo.queensSpain.alt"
    class="logo"
    loading="lazy"
    width="866"
    height="165"
    sizes="189px md:312"
  />

  <NuxtImg
    v-if="isQueens && isAmericas"
    :src="contextLeagueLogo.queensAmericas.src"
    :alt="contextLeagueLogo.queensAmericas.alt"
    class="logo"
    loading="lazy"
    width="866"
    height="206"
    sizes="189px md:312"
  />
</template>

<style scoped>
.logo {
  @apply w-[189px] md:w-[312px] h-auto;
}

.logo-kwc {
  @apply w-[160px] md:w-[220px] h-auto;
}
</style>
