<!--
// nuxt-ui/components/layout/NavCompetition.vue
-->
<script setup lang="ts">
import type { SocialMedia } from "~/src/region/domain/models/Region";
import type { Menu } from "~/src/region/domain/models/Menu";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";
import { useLinkUtils } from "~/nuxt-ui/Shared/composables/links";
import { initDropdowns } from "flowbite";
import ButtonsNavbar from "~/nuxt-ui/components/layout/header/ButtonsNavbar.vue";
import RegionMenus from "~/src/region/datasources/navigationMenu.json";
import FantasyIcon from "~/nuxt-ui/components/Shared/icons/FantasyIcon.vue";

type Props = {
  twitchIsLive: boolean;
};

const props = defineProps<Props>();

const localePath = useLocalePath();
const { isKings, webContext, isQueens } = useWebContext();
const competitionStore = useCompetitionStore();
const { isSpain, isAmericas, isWorldCup, isItaly } = useRegions();
const { locale } = useI18n();
const route = useRoute();
const webappStore = useWebappStore();
const { setLinkTo } = useLinkUtils();

const socialMedia: SocialMedia[] = competitionStore.region?.social[webContext] || [];
const liveButtonUrl: string | undefined =
  isWorldCup && locale.value === "es"
    ? "https://www.twitch.tv/kingsleague"
    : socialMedia.find(s => s.name === "twitch")?.url;

const showRegionCompetitionMenu = (regionSlug: string): boolean => {
  if (!webappStore.hoveringMegaMenu) {
    return webappStore.activeRegion === regionSlug;
  } else {
    return webappStore.regionHovered === regionSlug;
  }
};

const navMenus: Menu[] = RegionMenus[webContext];

onMounted(() => {
  initDropdowns();
});
</script>

<template>
  <div class="hidden lg:flex w-full justify-between items-center">
    <nav id="navbar-competition">
      <ul
        v-for="(menu, i) in navMenus"
        :key="i"
        class="flex lg:space-x-5 text-white text-sm uppercase"
        v-show="showRegionCompetitionMenu(menu.region)"
        @mouseover="webappStore.navCompetitionHovered = menu.region"
        @mouseleave="webappStore.navCompetitionHovered = undefined"
      >
        <li class="nav-link last:!mr-1" v-for="menuItem in menu.children" :key="menuItem.name">
          <div v-if="!!menuItem.children">
            <button
              :id="`${menuItem.name}Navbar${menu.region}`"
              :data-dropdown-toggle="`dropdown${menuItem.name}Navbar${menu.region}`"
              data-dropdown-trigger="hover"
              class="nav-link inline-flex items-center uppercase"
              type="button"
            >
              {{ $t(`header.navCompetition.${menuItem.name}`) }}
              <svg
                class="w-2 h-2 ml-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <div
              :id="`dropdown${menuItem.name}Navbar${menu.region}`"
              class="z-30 hidden rounded-lg shadow text-left bg-gray-900 border border-gray-700 py-2 w-[262px]"
            >
              <ul class="text-sm text-gray-400" :aria-labelledby="`${menuItem.name}Navbar${menu.region}`">
                <li class="subitem-dropdown" v-for="subItem in menuItem.children">
                  <nuxt-link
                    v-if="subItem.name === '12-13'"
                    no-prefetch
                    :to="setLinkTo(subItem.link, menu.region)"
                    :external="menu.region !== route.params.region"
                    class="subitem-link-navbar"
                    @click="webappStore.activeRegion = menu.region"
                  >
                    <span class="flex items-center">
                      {{ $t(`header.navCompetition.${subItem.name}`) }}

                      <!--                      lightning icon-->
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="featured-player-icon"
                      >
                        <rect width="20" height="20" rx="10" fill="currentColor" />
                        <path
                          d="M11.1637 5L6.57162 10.2728C6.53478 10.3115 6.51094 10.3593 6.50296 10.4102C6.49498 10.4611 6.50322 10.5131 6.52667 10.5598C6.55012 10.6065 6.58778 10.6459 6.63511 10.6734C6.68244 10.7009 6.7374 10.7151 6.79335 10.7145H8.83627V15L13.4284 9.72719C13.4652 9.68846 13.4891 9.64075 13.497 9.58983C13.505 9.53891 13.4968 9.48695 13.4733 9.44024C13.4499 9.39353 13.4122 9.35406 13.3649 9.3266C13.3176 9.29915 13.2626 9.28488 13.2066 9.28552H11.1637V5Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </nuxt-link>

                  <nuxt-link
                    v-else
                    no-prefetch
                    :to="setLinkTo(subItem.link, menu.region)"
                    :external="menu.region !== route.params.region"
                    :target="subItem.link?.includes('http') ? '_blank' : '_self'"
                    class="subitem-link-navbar"
                    @click="webappStore.activeRegion = menu.region"
                  >
                    {{ $t(`header.navCompetition.${subItem.name}`) }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </div>

          <nuxt-link
            v-else-if="menuItem.name === 'fantasy'"
            no-prefetch
            :to="menuItem.link"
            external
            target="_blank"
          >
            <span class="flex items-center gap-1">
              <fantasy-icon width="auto" :height="12" :region="menu.region"></fantasy-icon>
              {{ $t(`header.navCompetition.${menuItem.name}`) }}
            </span>
          </nuxt-link>

          <nuxt-link
            v-else
            :to="setLinkTo(menuItem.link, menu.region)"
            :no-prefetch="
              menuItem.name !== 'teams' || menuItem.name !== 'matches' || menuItem.name !== 'tables'
            "
            :external="menu.region !== route.params.region"
            :target="menuItem.link?.includes('http') ? '_blank' : '_self'"
            @click="webappStore.activeRegion = menu.region"
          >
            {{ $t(`header.navCompetition.${menuItem.name}`) }}
          </nuxt-link>
        </li>
      </ul>
    </nav>
    <div class="flex space-x-2">
      <buttons-navbar
        v-if="!isItaly"
        live
        tickets
        :store="!isItaly"
        :store-url="competitionStore.region?.storeUrl"
        :live-url="liveButtonUrl"
        :twitch-is-live="twitchIsLive"
      ></buttons-navbar>
    </div>
  </div>
</template>

<style scoped></style>
