// nuxt-ui/Shared/composables/links.ts

export function useLinkUtils() {
  const localePath = useLocalePath();

  const setLinkTo = (link: string, region: string) => {
    if (link.includes("http")) {
      return link;
    }

    if (link.startsWith("region")) {
      return localePath({
        name: link,
        params: { region: region },
      });
    } else {
      return localePath({
        name: link,
      });
    }
  };

  return {
    setLinkTo,
  };
}
