<!--
// nuxt-ui/components/Shared/icons/FantasyIcon.vue
-->
<script setup lang="ts">
type Props = {
  width?: number | string;
  height?: number | string;
  region: string;
};

const props = withDefaults(defineProps<Props>(), {
  height: 14,
  width: 14,
});

const isSpain = props.region === "espana";
</script>

<template>
  <svg
    v-if="isSpain"
    :width="width"
    :height="height"
    viewBox="0 0 345 341"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M122.9 226.4H213.7L262.5 141.9H122.9V84.5H295.6L344.4 0H0.799805V302.5L115.3 340.1L122.9 337.6V226.4Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped></style>
