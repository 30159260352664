<!--
// nuxt-ui/components/layout/header/DropbarMenuMobile.vue
-->
<script setup lang="ts">
import type { Menu } from "~/src/region/domain/models/Menu";
import SocialIcons from "~/nuxt-ui/components/Shared/SocialIcons.vue";
import ContextOtherLeaguesLogo from "~/nuxt-ui/components/context-shared/ContextOtherLeaguesLogo.vue";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";
import { useLinkUtils } from "~/nuxt-ui/Shared/composables/links";
import regionUiService from "~/src/region/infrastructure/ui-services/regionUiService";
import RegionMenus from "~/src/region/datasources/navigationMenu.json";
import HomeIcon from "~/nuxt-ui/components/Shared/icons/HomeIcon.vue";
import FeaturedPlayerLightningIcon from "~/nuxt-ui/components/Shared/icons/FeaturedPlayerLightningIcon.vue";

const { locale, locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const route = useRoute();
const { webContext } = useWebContext();
const competitionStore = useCompetitionStore();
const { getWebContextRegions } = regionUiService();
const { setLinkTo } = useLinkUtils();

const webAppStore = useWebappStore();
const localePath = useLocalePath();

const availableLocales = computed(() => {
  const _locales = locales.value;
  return !!_locales && _locales.filter(i => i.code !== locale.value);
});

const showNavMobile = defineModel("showNavMobile", { required: true });
const languagesContainerMobile = ref(false);
const dropdownChildrenContainer = ref({});

const regions = await getWebContextRegions();

const toggleLanguagesContainerMobile = () => {
  languagesContainerMobile.value = !languagesContainerMobile.value;
};

const socialProfilesMeta = competitionStore.region?.social[webContext];

const socialProfiles = socialProfilesMeta
  ? {
      twitter: socialProfilesMeta.find(s => s.name === "twitter")?.url,
      discord: socialProfilesMeta.find(s => s.name === "discord")?.url,
      instagram: socialProfilesMeta.find(s => s.name === "instagram")?.url,
      tiktok: socialProfilesMeta.find(s => s.name === "tiktok")?.url,
      youtube: socialProfilesMeta.find(s => s.name === "youtube")?.url,
      facebook: socialProfilesMeta.find(s => s.name === "facebook")?.url,
    }
  : {};

const hideMobileDropbar = (region?: string) => {
  showNavMobile.value = false;
  if (!!region) {
    webAppStore.activeRegion = region;
    webAppStore.regionHovered = webAppStore.activeRegion;
  }

  dropdownChildrenContainer.value = {};
  const theBody: HTMLBodyElement | null = document.querySelector("body");
  if (theBody) theBody.classList.remove("overflow-hidden");
};

const showRegionCompetitionMenu = (regionSlug: string): boolean => {
  return webAppStore.regionHovered === regionSlug;
};

const navMenus: Menu[] = RegionMenus[webContext];
</script>

<template>
  <div
    id="dropbarMenuMobile"
    :class="['dropbar-nav-mobile', !showNavMobile ? 'opacity-0 -top-96' : 'opacity-100 top-[72px]']"
  >
    <div class="grid grid-cols-[30%_1fr]">
      <div class="regions-column">
        <nav class="bg-white text-black">
          <ul>
            <li
              v-for="region in regions"
              :key="region.id"
              :id="`region-${region.slug}`"
              class="nav-region-mobile-item"
            >
              <a
                aria-current="page"
                :class="[
                  { 'active-region-mobile': webAppStore.activeRegion === region.slug },
                  {
                    'hovered-region-mobile': webAppStore.regionHovered === region.slug,
                  },
                  'nav-region-mobile-item-link',
                ]"
                @click="webAppStore.regionHovered = region.slug"
                >{{ region.name }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
      <div class="menu-items-region bg-gray-800">
        <nav>
          <ul v-for="(menu, i) in navMenus" :key="i" v-show="showRegionCompetitionMenu(menu.region)">
            <li class="nav-mobile-item">
              <a
                class="nav-mobile-item-link flex items-center"
                :href="localePath({ name: 'region', params: { region: menu.region } })"
                @click="hideMobileDropbar(menu.region)"
                ><span class="mr-2"><home-icon :width="16" :height="16" /></span>
                {{ $t("header.dropbarCompetition.homePage") }}</a
              >
            </li>
            <li class="nav-mobile-item" v-for="menuItem in menu.children" :key="menuItem.name">
              <div v-if="!!menuItem.children" class="nav-mobile-item !border-none">
                <button
                  :id="`${menuItem.name}MobileParent`"
                  class="flex justify-between px-4 text-sm items-center min-w-full min-h-11 relative z-[10]"
                  type="button"
                  @click="
                    dropdownChildrenContainer[`${menuItem.name + menu.region}Toggle`] =
                      !dropdownChildrenContainer[`${menuItem.name + menu.region}Toggle`]
                  "
                >
                  {{ $t(`header.navCompetition.${menuItem.name}`) }}
                  <span class="text-gray-400">
                    <svg
                      class="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  :class="
                    dropdownChildrenContainer[`${menuItem.name + menu.region}Toggle`] ? 'h-auto' : 'h-0'
                  "
                  class="transition-[height] duration-300 ease-in-out overflow-hidden bg-gray-900"
                >
                  <ul class="text-sm font-normal text-gray-300">
                    <li
                      v-for="subItem in menuItem.children"
                      class="hover:text-white h-11 flex justify-start items-center relative"
                    >
                      <nuxt-link
                        v-if="subItem.name === '12-13'"
                        no-prefetch
                        :to="setLinkTo(subItem.link, menu.region)"
                        :external="menu.region !== route.params.region"
                        class="nav-mobile-item-link"
                        @click="hideMobileDropbar(menu.region)"
                      >
                        {{ $t(`header.navCompetition.${subItem.name}`) }}
                        <span class="ml-1.5">
                          <featured-player-lightning-icon
                            :width="15"
                            :height="15"
                          ></featured-player-lightning-icon>
                        </span>
                      </nuxt-link>

                      <nuxt-link
                        v-else
                        no-prefetch
                        :to="setLinkTo(subItem.link, menu.region)"
                        :external="menu.region !== route.params.region"
                        :target="subItem.link?.includes('http') ? '_blank' : '_self'"
                        class="nav-mobile-item-link"
                        @click="hideMobileDropbar(menu.region)"
                      >
                        {{ $t(`header.navCompetition.${subItem.name}`) }}
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </div>

              <nuxt-link
                v-else-if="menuItem.name === 'fantasy'"
                no-prefetch
                :to="menuItem.link"
                external
                target="_blank"
                class="nav-mobile-item-link"
                @click="hideMobileDropbar(menu.region)"
              >
                <span class="flex items-center gap-1">
                  <svg
                    width="auto"
                    height="12"
                    viewBox="0 0 345 341"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M122.9 226.4H213.7L262.5 141.9H122.9V84.5H295.6L344.4 0H0.799805V302.5L115.3 340.1L122.9 337.6V226.4Z"
                      fill="currentColor"
                    />
                  </svg>
                  {{ $t(`header.navCompetition.${menuItem.name}`) }}
                </span>
              </nuxt-link>

              <nuxt-link
                v-else
                :to="setLinkTo(menuItem.link, menu.region)"
                :no-prefetch="
                  menuItem.name !== 'teams' || menuItem.name !== 'matches' || menuItem.name !== 'tables'
                "
                :external="menu.region !== route.params.region"
                :target="menuItem.link?.includes('http') ? '_blank' : '_self'"
                @click="hideMobileDropbar(menu.region)"
                class="nav-mobile-item-link"
                >{{ $t(`header.navCompetition.${menuItem.name}`) }}</nuxt-link
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="bg-gray-900 border-b border-b-gray-700">
      <button class="nav-mobile-language-item" @click="toggleLanguagesContainerMobile">
        {{ $t("header.dropbarCompetition.language") }}
        <span class="font-semibold text-sm py-1 text-center flex items-center text-gray-500"
          >{{ locale }}
          <svg
            class="w-3 h-3 ml-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            /></svg
        ></span>
      </button>
      <div
        :class="languagesContainerMobile ? 'h-auto' : 'h-0'"
        class="transition-[height] duration-300 ease-in-out overflow-hidden"
      >
        <ul class="text-sm font-normal text-gray-300">
          <li v-for="avLocale in availableLocales" :key="avLocale.code">
            <a :href="switchLocalePath(avLocale.code)" class="block p-2 px-4 hover:text-white">
              {{ avLocale.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-mobile-additional-context-info-item">
      <social-icons :social-profiles="socialProfiles" height="17"></social-icons>
      <context-other-leagues-logo></context-other-leagues-logo>
    </div>
  </div>
</template>

<style scoped>
.dropbar-nav-mobile {
  @apply text-white z-10 text-sm font-medium w-screen absolute left-0 right-0 transition-all duration-300 lg:hidden;
}

.regions-column {
  @apply bg-white;
}

.nav-region-mobile-item {
  @apply h-12 border-b border-b-neutral-600 relative;
}

.nav-region-mobile-item-link {
  @apply absolute top-0 left-0 right-0 bottom-0 flex items-center justify-start pl-4 hover:text-black;
}

.nav-mobile-item {
  @apply min-h-11 border-b border-b-gray-700 relative hover:bg-black;
}

.nav-mobile-item-link {
  @apply absolute top-0 left-0 right-0 bottom-0 flex items-center justify-start px-4;
}

.nav-mobile-language-item {
  @apply text-gray-400 flex justify-between py-1 px-4 text-sm items-center min-w-full;
}

.nav-mobile-additional-context-info-item {
  @apply bg-gray-900 text-white flex justify-between px-4 text-sm items-center border-b border-b-gray-700 py-3;
}

.menu-items-region {
  @apply bg-gray-800 min-h-[266px];
}
</style>
