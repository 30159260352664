<!--
// nuxt-ui/components/context-shared/ContextOtherLeaguesLogo.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import ContextLeagueLogo from "~/nuxt-ui/components/context-shared/ContextLeagueLogo.vue";
const { isKings, isQueens, isPrince } = useWebContext();

type Props = {
  width?: string;
  height?: string;
};
withDefaults(defineProps<Props>(), {
  height: "h-[38px]",
  width: "w-auto",
});
</script>

<template>
  <div v-if="isKings" class="container-logos-other-leagues">
    <span>
      <context-league-logo :height="height" :width="width" context="queens" new-window></context-league-logo>
    </span>
    <span>
      <context-league-logo :height="height" :width="width" context="prince" new-window></context-league-logo>
    </span>
  </div>
  <div v-if="isQueens" class="container-logos-other-leagues">
    <span>
      <context-league-logo :height="height" :width="width" context="kings" new-window></context-league-logo>
    </span>
    <span>
      <context-league-logo :height="height" :width="width" context="prince" new-window></context-league-logo>
    </span>
  </div>
  <div v-if="isPrince" class="container-logos-other-leagues">
    <span>
      <context-league-logo :height="height" :width="width" context="kings" new-window></context-league-logo>
    </span>
    <span>
      <context-league-logo :height="height" :width="width" context="queens" new-window></context-league-logo>
    </span>
  </div>
</template>

<style scoped>
.container-logos-other-leagues {
  @apply flex gap-3 justify-center items-center;
}
</style>
