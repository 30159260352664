// nuxt-ui/stores/webappStore.ts

import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";

export const useWebappStore = defineStore("webappStore", () => {
  const route = useRoute();
  const competitionStore = useCompetitionStore();

  const setActiveRegion = (): string => {
    return route.params.region
      ? (route.params.region as string)
      : competitionStore.region
        ? competitionStore.region.slug
        : "espana";
  };

  const activeRegion = ref<string>(setActiveRegion());
  const regionHovered = ref<string | undefined>(setActiveRegion());
  const navCompetitionHovered = ref<string | undefined>();
  const hoveringMegaMenu = ref<boolean>();

  watchEffect(() => {
    if (hoveringMegaMenu.value === false) {
      regionHovered.value = activeRegion.value;
    }
  });

  return {
    activeRegion,
    regionHovered,
    navCompetitionHovered,
    hoveringMegaMenu,
    setActiveRegion,
  };
});
