<!--
// nuxt-ui/components/layout/header/LangSwitcher.vue
-->
<script setup lang="ts">
const { locale, locales, setLocaleCookie } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const router = useRouter();

const availableLocales = computed(() => {
  return locales.value.filter(i => i.code !== locale.value);
});

type Props = {
  dark?: boolean;
  buttonId: string;
  dropdownToggle: string;
};

defineProps<Props>();
</script>

<template>
  <button
    :id="buttonId"
    :data-dropdown-toggle="dropdownToggle"
    :class="[
      'font-semibold text-sm py-1 text-center flex items-center',
      dark ? 'text-gray-400' : 'text-gray-500',
    ]"
    type="button"
  >
    {{ locale }}
    <svg
      class="w-2 h-2 ml-1"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 4 4 4-4"
      />
    </svg>
  </button>

  <!-- Dropdown menu -->
  <div
    :id="dropdownToggle"
    :class="[
      'z-30 hidden divide-y divide-gray-100 rounded-lg shadow w-10 text-center',
      dark ? 'bg-zinc-900' : 'bg-white',
    ]"
  >
    <ul
      :class="['text-sm font-semibold', dark ? 'text-gray-400' : 'text-gray-500']"
      :aria-labelledby="buttonId"
    >
      <li v-for="avLocale in availableLocales" :key="avLocale.code">
        <a
          :href="switchLocalePath(avLocale.code)"
          :class="['block p-2 lowercase', dark ? 'hover:text-white' : 'hover:text-black']"
          >{{ avLocale.code }}</a
        >
      </li>
    </ul>
  </div>
</template>

<style scoped></style>
